import { axiosInstance } from '../../network/apis';
const handlerEnabled = true;

const getModels = async () => {
  return await axiosInstance.get(`/models`, { handlerEnabled });
};

const getModelDetails = async (id) => {
  return await axiosInstance.get(`/models/${id}`, { handlerEnabled });
};

const addModel = async (reqBody) => {
  return await axiosInstance.post(`/models`, { ...reqBody }, { handlerEnabled });
};

const editModel = async ({ id, ...rest }) => {
  return await axiosInstance.put(`/models/edit/${id}`, { ...rest }, { handlerEnabled });
};

const archiveModel = async (id) => {
  return await axiosInstance.put(`/models/archive/${id}`, { handlerEnabled });
};

const addModelField = async (reqBody) => {
  return await axiosInstance.post(`/models/add-field`, { ...reqBody }, { handlerEnabled });
};

const editModelField = async ({ id, ...rest }) => {
  return await axiosInstance.put(`/models/edit-field/${id}`, { ...rest }, { handlerEnabled });
};

const archiveModelField = async (id) => {
  return await axiosInstance.put(`/models/archive-field/${id}`, { handlerEnabled });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getModels,
  addModel,
  editModel,
  archiveModel,
  getModelDetails,
  addModelField,
  editModelField,
  archiveModelField,
};
