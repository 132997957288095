import { call, put } from 'redux-saga/effects';
import API from './FieldApis';
import * as actions from './FieldActions';
import { dispatchSnackbarError } from '../../utils/Shared';
import { takeLatest } from 'redux-saga/effects';
import * as types from './FieldTypes';

export function* getFieldReqSaga() {
  try {
    const response = yield call(API.getFields);

    yield put(actions.getFieldsSuccess(response.data.fields));
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.getFieldsFailure(err.response.data));
  }
}

export function* addFieldReqSaga({ payload }) {
  try {
    yield call(API.addField, { ...payload });
    yield put(actions.addFieldSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.addFieldFailure(err.response.data));
  }
}

export function* editFieldReqSaga({ payload }) {
  try {
    yield call(API.editField, { ...payload });
    yield put(actions.editFieldSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.editFieldFailure(err.response.data));
  }
}

export function* archiveFieldReqSaga({ payload }) {
  try {
    yield call(API.archiveField, payload);
    yield put(actions.archiveFieldSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.archiveFieldFailure(err.response.data));
  }
}

export function* FieldSagas() {
  yield takeLatest(types.GET_FIELD_BEGIN, getFieldReqSaga);
  yield takeLatest(types.ADD_FIELD_BEGIN, addFieldReqSaga);
  yield takeLatest(types.EDIT_FIELD_BEGIN, editFieldReqSaga);
  yield takeLatest(types.ARCHIVE_FIELD_BEGIN, archiveFieldReqSaga);
}
