import * as types from './ContentTypes';

const INITIAL_STATE = {
  submitting: false,
  refresh: false,
  contents: [],
  details: null,
  error: null,
};

const ContentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_CONTENTS_SUCCESS:
      return {
        ...state,
        contents: action.payload,
        error: null,
      };

    case types.GET_CONTENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case types.GET_CONTENT_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload,
        error: null,
      };

    case types.GET_CONTENT_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case types.ADD_CONTENT_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case types.ADD_CONTENT_SUCCESS:
      return {
        ...state,
        submitting: false,
        refresh: true,
        error: null,
      };

    case types.ADD_CONTENT_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };

    case types.EDIT_CONTENT_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case types.EDIT_CONTENT_SUCCESS:
      return {
        ...state,
        submitting: false,
        refresh: true,
        error: null,
      };

    case types.EDIT_CONTENT_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };

    case types.ARCHIVE_CONTENT_SUCCESS:
      return {
        ...state,
        refresh: true,
        error: null,
      };

    case types.ARCHIVE_CONTENT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case types.SAVE_CONTENT_DATA_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case types.SAVE_CONTENT_DATA_SUCCESS:
      return {
        ...state,
        submitting: false,
        refresh: true,
        error: null,
      };

    case types.SAVE_CONTENT_DATA_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };

    case types.RESET_REFRESH:
      return {
        ...state,
        refresh: false,
      };

    default:
      return state;
  }
};

export default ContentReducer;
