import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Avatar from 'react-avatar';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux/es/exports';
import { Container, Navbar, Nav } from 'react-bootstrap';

import DropdownMenu from '../DropdownMenu';
import { logoutBegin } from '../../redux/Auth/AuthActions';

const Header = () => {
  const {
    auth: { tokens },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutBegin(tokens.refresh.token));
  };

  return (
    <Navbar sticky="top" bg="dark" variant="dark" className="header">
      <Container fluid className="px-4">
        <Navbar.Brand as={RouterLink} to="/">
          Admin Console
        </Navbar.Brand>

        <Nav className="ms-auto">
          <DropdownMenu
            menu={[
              {
                label: 'Profile',
                onClick: () => null,
              },
              {
                label: 'Logout',
                onClick: () => handleLogout(),
              },
            ]}
            style={{
              button: {
                padding: 0,
              },
              menu: {
                minWidth: 150,
              },
            }}
            icon={
              <Avatar
                src={null}
                name={`Super Admin`}
                size="32"
                className="ff-primary"
                style={{
                  borderRadius: '50%',
                  overflow: 'hidden',
                }}
              />
            }
          />
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
