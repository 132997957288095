import React from 'react';
import { Route, Routes } from 'react-router-dom';
import * as LazyComponent from '../utils/LazyLoaded';
import PrivateRoute from '../utils/PrivateRoute';

const RoutesWrapper = () => {
  return (
    <Routes>
      <Route path="/login" element={<LazyComponent.Login />} />
      <Route path="/" element={<PrivateRoute component={LazyComponent.Home} />} />
      <Route path="/fields/*" element={<PrivateRoute component={LazyComponent.Fields} />} />
      <Route path="/models/details/:id/*" element={<PrivateRoute component={LazyComponent.ModelDetails} />} />
      <Route path="/models/*" element={<PrivateRoute component={LazyComponent.Models} />} />
      <Route path="/contents/details/:id/*" element={<PrivateRoute component={LazyComponent.ContentDetails} />} />
      <Route path="/contents/*" element={<PrivateRoute component={LazyComponent.Contents} />} />
    </Routes>
  );
};

export default RoutesWrapper;
