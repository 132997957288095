import * as types from './AuthTypes';

export const loginBegin = (payload) => ({
  type: types.LOGIN_BEGIN,
  payload,
});

export const loginSuccess = (payload) => ({
  type: types.LOGIN_SUCCESS,
  payload,
});

export const loginFailure = (payload) => ({
  type: types.LOGIN_FAILURE,
  payload,
});

export const logoutBegin = (payload) => ({
  type: types.LOGOUT_BEGIN,
  payload,
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
});
