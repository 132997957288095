import { axiosInstance } from '../../network/apis';
const handlerEnabled = true;

const login = async ({ email, password }) => {
  return await axiosInstance.post(`/auth/login`, { email, password }, { handlerEnabled });
};

const logout = async (refreshToken) => {
  return await axiosInstance.post(`/auth/logout`, { refreshToken }, { handlerEnabled });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  logout,
};
