import React from 'react';

export const Login = React.lazy(() => import('../containers/Login'));
export const Home = React.lazy(() => import('../containers/Home'));
export const Fields = React.lazy(() => import('../containers/Fields'));
export const Models = React.lazy(() => import('../containers/Models'));
export const ModelDetails = React.lazy(() => import('../containers/Models/ModelDetails'));
export const Contents = React.lazy(() => import('../containers/Contents'));
export const ContentDetails = React.lazy(() => import('../containers/Contents/ContentDetails'));
export const NotFound = React.lazy(() => import('../components/NotFound'));
