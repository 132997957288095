import store from '../../redux/store';
import { loader } from '../../redux/Loader/LoaderActions';
import { logoutBegin } from '../../redux/Auth/AuthActions';

export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ? false : true;
};

export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    const {
      auth: { tokens },
    } = store.getState((state) => state);

    // Modify request here
    store.dispatch(loader(true));

    if (!request.url.includes('/auth')) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${tokens.access.token}`,
      };
    }
  }

  return request;
};

export const successHandler = (response) => {
  if (isHandlerEnabled(response)) {
    // Hanlde Response
    store.dispatch(loader(false));
  }
  return response.data;
};

export const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    const {
      auth: { tokens },
    } = store.getState((state) => state);

    store.dispatch(loader(false));
    // You can decide what you need to do to handle errors.
    // here's example for unautherized user to log them out .
    // error.response.status === 401 && Auth.signOut();

    if (error.response.status === 401) store.dispatch(logoutBegin(tokens.refresh.token));
  }
  return Promise.reject({ ...error });
};
