import { call, put } from 'redux-saga/effects';
import API from './AuthApis';
import * as actions from './AuthActions';
import { dispatchSnackbarError } from '../../utils/Shared';
import { takeLatest } from 'redux-saga/effects';
import * as types from './AuthTypes';

export function* loginRequestSaga({ payload }) {
  try {
    const response = yield call(API.login, { ...payload });

    yield put(
      actions.loginSuccess({
        isAuthenticated: true,
        tokens: response.data.tokens,
      })
    );
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.loginFailure(err.response.data));
  }
}

export function* logoutRequestSaga({ payload }) {
  try {
    yield call(API.logout, payload);

    yield put(actions.logoutSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
  }
}

export function* AuthSagas() {
  yield takeLatest(types.LOGIN_BEGIN, loginRequestSaga);
  yield takeLatest(types.LOGOUT_BEGIN, logoutRequestSaga);
}
