import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/exports';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const {
    auth: { isAuthenticated },
  } = useSelector((state) => state);

  if (isAuthenticated) return <Component {...rest} />;
  else return <Navigate to={{ pathname: '/login' }} />;
};

export default PrivateRoute;
