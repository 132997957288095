import * as types from './ModelTypes';

export const getModelsBegin = () => ({
  type: types.GET_MODEL_BEGIN,
});

export const getModelsSuccess = (payload) => ({
  type: types.GET_MODEL_SUCCESS,
  payload,
});

export const getModelsFailure = (payload) => ({
  type: types.GET_MODEL_FAILURE,
  payload,
});

export const addModelBegin = (payload) => ({
  type: types.ADD_MODEL_BEGIN,
  payload,
});

export const addModelSuccess = () => ({
  type: types.ADD_MODEL_SUCCESS,
});

export const addModelFailure = (payload) => ({
  type: types.ADD_MODEL_FAILURE,
  payload,
});

export const editModelBegin = (payload) => ({
  type: types.EDIT_MODEL_BEGIN,
  payload,
});

export const editModelSuccess = () => ({
  type: types.EDIT_MODEL_SUCCESS,
});

export const editModelFailure = (payload) => ({
  type: types.EDIT_MODEL_FAILURE,
  payload,
});

export const archiveModelBegin = (payload) => ({
  type: types.ARCHIVE_MODEL_BEGIN,
  payload,
});

export const archiveModelSuccess = () => ({
  type: types.ARCHIVE_MODEL_SUCCESS,
});

export const archiveModelFailure = (payload) => ({
  type: types.ARCHIVE_MODEL_FAILURE,
  payload,
});

export const getDetailsBegin = (payload) => ({
  type: types.GET_DETAILS_BEGIN,
  payload,
});

export const getDetailsSuccess = (payload) => ({
  type: types.GET_DETAILS_SUCCESS,
  payload,
});

export const getDetailsFailure = (payload) => ({
  type: types.GET_DETAILS_FAILURE,
  payload,
});

export const addModelFieldBegin = (payload) => ({
  type: types.ADD_MODEL_FIELD_BEGIN,
  payload,
});

export const addModelFieldSuccess = () => ({
  type: types.ADD_MODEL_FIELD_SUCCESS,
});

export const addModelFieldFailure = (payload) => ({
  type: types.ADD_MODEL_FIELD_FAILURE,
  payload,
});

export const editModelFieldBegin = (payload) => ({
  type: types.EDIT_MODEL_FIELD_BEGIN,
  payload,
});

export const editModelFieldSuccess = () => ({
  type: types.EDIT_MODEL_FIELD_SUCCESS,
});

export const editModelFieldFailure = (payload) => ({
  type: types.EDIT_MODEL_FIELD_FAILURE,
  payload,
});

export const archiveModelFieldBegin = (payload) => ({
  type: types.ARCHIVE_MODEL_FIELD_BEGIN,
  payload,
});

export const archiveModelFieldSuccess = () => ({
  type: types.ARCHIVE_MODEL_FIELD_SUCCESS,
});

export const archiveModelFieldFailure = (payload) => ({
  type: types.ARCHIVE_MODEL_FIELD_FAILURE,
  payload,
});

export const resetRefresh = () => ({
  type: types.RESET_REFRESH,
});
