import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Navbar, Container } from 'react-bootstrap';

const BlankLayout = ({ children }) => {
  return (
    <div className="w-100 h-100 layout--blank">
      <Navbar fixed="top" bg="dark" variant="dark" className="header">
        <Container fluid className="px-4">
          <Navbar.Brand as={RouterLink} to="/" color="#fff">
            Admin Console
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid className="px-0 content">
        {children}
      </Container>
    </div>
  );
};

BlankLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlankLayout;
