import { call, put } from 'redux-saga/effects';
import API from './ModelApis';
import * as actions from './ModelActions';
import { dispatchSnackbarError } from '../../utils/Shared';
import { takeLatest } from 'redux-saga/effects';
import * as types from './ModelTypes';

export function* getModelReqSaga() {
  try {
    const response = yield call(API.getModels);

    yield put(actions.getModelsSuccess(response.data.models));
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.getModelsFailure(err.response.data));
  }
}
export function* addModelReqSaga({ payload }) {
  try {
    yield call(API.addModel, payload);
    yield put(actions.addModelSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.addModelFailure(err.response.data));
  }
}

export function* editModelReqSaga({ payload }) {
  try {
    yield call(API.editModel, { ...payload });
    yield put(actions.editModelSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.editModelFailure(err.response.data));
  }
}

export function* archiveModelReqSaga({ payload }) {
  try {
    yield call(API.archiveModel, payload);
    yield put(actions.archiveModelSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.archiveModelFailure(err.response.data));
  }
}

export function* getModelDetailsReqSaga({ payload }) {
  try {
    const response = yield call(API.getModelDetails, payload);

    yield put(actions.getDetailsSuccess(response.data.details));
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.getDetailsFailure(err.response.data));
  }
}

export function* addModelFieldReqSaga({ payload }) {
  try {
    yield call(API.addModelField, payload);
    yield put(actions.addModelFieldSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.addModelFieldFailure(err.response.data));
  }
}

export function* editModelFieldReqSaga({ payload }) {
  try {
    yield call(API.editModelField, { ...payload });
    yield put(actions.editModelFieldSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.editModelFieldFailure(err.response.data));
  }
}

export function* archiveModelFieldReqSaga({ payload }) {
  try {
    yield call(API.archiveModelField, payload);
    yield put(actions.archiveModelFieldSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.archiveModelFieldFailure(err.response.data));
  }
}

export function* ModelSagas() {
  yield takeLatest(types.GET_MODEL_BEGIN, getModelReqSaga);

  yield takeLatest(types.ADD_MODEL_BEGIN, addModelReqSaga);
  yield takeLatest(types.EDIT_MODEL_BEGIN, editModelReqSaga);
  yield takeLatest(types.ARCHIVE_MODEL_BEGIN, archiveModelReqSaga);

  yield takeLatest(types.GET_DETAILS_BEGIN, getModelDetailsReqSaga);
  yield takeLatest(types.ADD_MODEL_FIELD_BEGIN, addModelFieldReqSaga);
  yield takeLatest(types.EDIT_MODEL_FIELD_BEGIN, editModelFieldReqSaga);
  yield takeLatest(types.ARCHIVE_MODEL_FIELD_BEGIN, archiveModelFieldReqSaga);
}
