import React from 'react';
import { useSelector } from 'react-redux/es/exports';

import DefaultLayout from './DefaultLayout';
import BlankLayout from './BlankLayout';

function MasterLayout({ children }) {
  const {
    auth: { isAuthenticated },
  } = useSelector((state) => state);

  const Layout = isAuthenticated ? DefaultLayout : BlankLayout;

  return <Layout>{children}</Layout>;
}

export default MasterLayout;
