import { axiosInstance } from '../../network/apis';
const handlerEnabled = true;

const getContents = async () => {
  return await axiosInstance.get(`/contents`, { handlerEnabled });
};

const getContentDetails = async (id) => {
  return await axiosInstance.get(`/contents/${id}`, { handlerEnabled });
};

const addContent = async (reqBody) => {
  return await axiosInstance.post(`/contents`, { ...reqBody }, { handlerEnabled });
};

const editContent = async ({ id, ...rest }) => {
  return await axiosInstance.put(`/contents/edit/${id}`, { ...rest }, { handlerEnabled });
};

const archiveContent = async (id) => {
  return await axiosInstance.put(`/contents/archive/${id}`, { handlerEnabled });
};

const saveContentData = async ({ id, ...rest }) => {
  return await axiosInstance.post(`/contents/data/${id}`, { ...rest }, { handlerEnabled });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getContents,
  getContentDetails,
  addContent,
  editContent,
  archiveContent,
  saveContentData,
};
