export const GET_MODEL_BEGIN = 'GET_MODEL_BEGIN';
export const GET_MODEL_SUCCESS = 'GET_MODEL_SUCCESS';
export const GET_MODEL_FAILURE = 'GET_MODEL_FAILURE';

export const ADD_MODEL_BEGIN = 'ADD_MODEL_BEGIN';
export const ADD_MODEL_SUCCESS = 'ADD_MODEL_SUCCESS';
export const ADD_MODEL_FAILURE = 'ADD_MODEL_FAILURE';

export const EDIT_MODEL_BEGIN = 'EDIT_MODEL_BEGIN';
export const EDIT_MODEL_SUCCESS = 'EDIT_MODEL_SUCCESS';
export const EDIT_MODEL_FAILURE = 'EDIT_MODEL_FAILURE';

export const ARCHIVE_MODEL_BEGIN = 'ARCHIVE_MODEL_BEGIN';
export const ARCHIVE_MODEL_SUCCESS = 'ARCHIVE_MODEL_SUCCESS';
export const ARCHIVE_MODEL_FAILURE = 'ARCHIVE_MODEL_FAILURE';

export const GET_DETAILS_BEGIN = 'GET_DETAILS_BEGIN';
export const GET_DETAILS_SUCCESS = 'GET_DETAILS_SUCCESS';
export const GET_DETAILS_FAILURE = 'GET_DETAILS_FAILURE';

export const ADD_MODEL_FIELD_BEGIN = 'ADD_MODEL_FIELD_BEGIN';
export const ADD_MODEL_FIELD_SUCCESS = 'ADD_MODEL_FIELD_SUCCESS';
export const ADD_MODEL_FIELD_FAILURE = 'ADD_MODEL_FIELD_FAILURE';

export const EDIT_MODEL_FIELD_BEGIN = 'EDIT_MODEL_FIELD_BEGIN';
export const EDIT_MODEL_FIELD_SUCCESS = 'EDIT_MODEL_FIELD_SUCCESS';
export const EDIT_MODEL_FIELD_FAILURE = 'EDIT_MODEL_FIELD_FAILURE';

export const ARCHIVE_MODEL_FIELD_BEGIN = 'ARCHIVE_MODEL_FIELD_BEGIN';
export const ARCHIVE_MODEL_FIELD_SUCCESS = 'ARCHIVE_MODEL_FIELD_SUCCESS';
export const ARCHIVE_MODEL_FIELD_FAILURE = 'ARCHIVE_MODEL_FIELD_FAILURE';

export const RESET_REFRESH = 'RESET_REFRESH';
