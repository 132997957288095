export const GET_FIELD_BEGIN = 'GET_FIELD_BEGIN';
export const GET_FIELD_SUCCESS = 'GET_FIELD_SUCCESS';
export const GET_FIELD_FAILURE = 'GET_FIELD_FAILURE';

export const ADD_FIELD_BEGIN = 'ADD_FIELD_BEGIN';
export const ADD_FIELD_SUCCESS = 'ADD_FIELD_SUCCESS';
export const ADD_FIELD_FAILURE = 'ADD_FIELD_FAILURE';

export const EDIT_FIELD_BEGIN = 'EDIT_FIELD_BEGIN';
export const EDIT_FIELD_SUCCESS = 'EDIT_FIELD_SUCCESS';
export const EDIT_FIELD_FAILURE = 'EDIT_FIELD_FAILURE';

export const ARCHIVE_FIELD_BEGIN = 'ARCHIVE_FIELD_BEGIN';
export const ARCHIVE_FIELD_SUCCESS = 'ARCHIVE_FIELD_SUCCESS';
export const ARCHIVE_FIELD_FAILURE = 'ARCHIVE_FIELD_FAILURE';

export const RESET_REFRESH = 'RESET_REFRESH';
