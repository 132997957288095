import React from 'react';
import PropTypes from 'prop-types';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';

const DefaultLayout = ({ children }) => {
  return (
    <div className="w-100 layout--default">
      <Header title="Home" />
      <Sidebar />
      {children}
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
