import { axiosInstance } from '../../network/apis';
const handlerEnabled = true;

const getFields = async () => {
  return await axiosInstance.get(`/fields`, { handlerEnabled });
};

const addField = async ({ name, type, description }) => {
  return await axiosInstance.post(`/fields`, { name, type, description }, { handlerEnabled });
};

const editField = async ({ id, name, type, description }) => {
  return await axiosInstance.put(`/fields/edit/${id}`, { name, type, description }, { handlerEnabled });
};

const archiveField = async (id) => {
  return await axiosInstance.put(`/fields/archive/${id}`, { handlerEnabled });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getFields,
  addField,
  editField,
  archiveField,
};
