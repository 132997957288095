import * as types from './ContentTypes';

export const getContentsBegin = () => ({
  type: types.GET_CONTENTS_BEGIN,
});

export const getContentsSuccess = (payload) => ({
  type: types.GET_CONTENTS_SUCCESS,
  payload,
});

export const getContentsFailure = (payload) => ({
  type: types.GET_CONTENTS_FAILURE,
  payload,
});

export const getContentDetailsBegin = (payload) => ({
  type: types.GET_CONTENT_DETAILS_BEGIN,
  payload,
});

export const getContentDetailsSuccess = (payload) => ({
  type: types.GET_CONTENT_DETAILS_SUCCESS,
  payload,
});

export const getContentDetailsFailure = (payload) => ({
  type: types.GET_CONTENT_DETAILS_FAILURE,
  payload,
});

export const addContentBegin = (payload) => ({
  type: types.ADD_CONTENT_BEGIN,
  payload,
});

export const addContentSuccess = () => ({
  type: types.ADD_CONTENT_SUCCESS,
});

export const addContentFailure = (payload) => ({
  type: types.ADD_CONTENT_FAILURE,
  payload,
});

export const editContentBegin = (payload) => ({
  type: types.EDIT_CONTENT_BEGIN,
  payload,
});

export const editContentSuccess = () => ({
  type: types.EDIT_CONTENT_SUCCESS,
});

export const editContentFailure = (payload) => ({
  type: types.EDIT_CONTENT_FAILURE,
  payload,
});

export const archiveContentBegin = (payload) => ({
  type: types.ARCHIVE_CONTENT_BEGIN,
  payload,
});

export const archiveContentSuccess = () => ({
  type: types.ARCHIVE_CONTENT_SUCCESS,
});

export const archiveContentFailure = (payload) => ({
  type: types.ARCHIVE_CONTENT_FAILURE,
  payload,
});

export const saveContentDataBegin = (payload) => ({
  type: types.SAVE_CONTENT_DATA_BEGIN,
  payload,
});

export const saveContentDataSuccess = () => ({
  type: types.SAVE_CONTENT_DATA_SUCCESS,
});

export const saveContentDataFailure = (payload) => ({
  type: types.SAVE_CONTENT_DATA_FAILURE,
  payload,
});

export const resetRefresh = () => ({
  type: types.RESET_REFRESH,
});
