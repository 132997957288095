import { fork, all } from 'redux-saga/effects';
import { AuthSagas } from '../Auth/AuthSagas';
import { FieldSagas } from '../Fields/FieldSagas';
import { ModelSagas } from '../Models/ModelSagas';
import { ContentSagas } from '../Contents/ContentSagas';

export function* watchSagas() {
  //Combine sagas with
  yield all([AuthSagas(), FieldSagas(), ModelSagas(), ContentSagas()]);
  // OR
  // yield all([fork(FeatureSaga1)]);
}
