import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import history from './routes/History';
import Loader from './components/Loader/Loader';
import MasterLayout from './layouts/MasterLayout';
import RoutesWrapper from './routes/RoutesWrapper';

import './assets/scss/style.scss';
import './App.scss';

function App() {
  return (
    <Router history={history}>
      <Suspense fallback={<Loader />}>
        <MasterLayout>
          <RoutesWrapper />
        </MasterLayout>
      </Suspense>
    </Router>
  );
}

export default App;
