import * as types from './FieldTypes';

export const getFieldsBegin = () => ({
  type: types.GET_FIELD_BEGIN,
});

export const getFieldsSuccess = (payload) => ({
  type: types.GET_FIELD_SUCCESS,
  payload,
});

export const getFieldsFailure = (payload) => ({
  type: types.GET_FIELD_FAILURE,
  payload,
});

export const addFieldBegin = (payload) => ({
  type: types.ADD_FIELD_BEGIN,
  payload,
});

export const addFieldSuccess = () => ({
  type: types.ADD_FIELD_SUCCESS,
});

export const addFieldFailure = (payload) => ({
  type: types.ADD_FIELD_FAILURE,
  payload,
});

export const editFieldBegin = (payload) => ({
  type: types.EDIT_FIELD_BEGIN,
  payload,
});

export const editFieldSuccess = () => ({
  type: types.EDIT_FIELD_SUCCESS,
});

export const editFieldFailure = (payload) => ({
  type: types.EDIT_FIELD_FAILURE,
  payload,
});

export const archiveFieldBegin = (payload) => ({
  type: types.ARCHIVE_FIELD_BEGIN,
  payload,
});

export const archiveFieldSuccess = () => ({
  type: types.ARCHIVE_FIELD_SUCCESS,
});

export const archiveFieldFailure = (payload) => ({
  type: types.ARCHIVE_FIELD_FAILURE,
  payload,
});

export const resetRefresh = () => ({
  type: types.RESET_REFRESH,
});
