import { configureStore } from '@reduxjs/toolkit';
import reducers from '../reducers';
import createSagaMiddleware from 'redux-saga';
import { watchSagas } from '../sagas';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist/es';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth'],
};

const pReducer = persistReducer(persistConfig, reducers);
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({ reducer: pReducer, middleware: () => [sagaMiddleware] });
export const persistor = persistStore(store);

sagaMiddleware.run(watchSagas);

export default store;
