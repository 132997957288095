import store from '../redux/store';
import { showSnackbarAction } from '../redux/Snackbar/SnackbarActions';

// To show error message that returned from backend
export function dispatchSnackbarError(message) {
  if (message) {
    store.dispatch(showSnackbarAction(message, 'error'));
  }
}
// To show success message after any success request if needed and rendered from locale files
export function dispatchSnackbarSuccess(message) {
  store.dispatch(showSnackbarAction(message, 'success'));
}
