export const GET_CONTENTS_BEGIN = 'GET_CONTENTS_BEGIN';
export const GET_CONTENTS_SUCCESS = 'GET_CONTENTS_SUCCESS';
export const GET_CONTENTS_FAILURE = 'GET_CONTENTS_FAILURE';

export const GET_CONTENT_DETAILS_BEGIN = 'GET_CONTENT_DETAILS_BEGIN';
export const GET_CONTENT_DETAILS_SUCCESS = 'GET_CONTENT_DETAILS_SUCCESS';
export const GET_CONTENT_DETAILS_FAILURE = 'GET_CONTENT_DETAILS_FAILURE';

export const ADD_CONTENT_BEGIN = 'ADD_CONTENT_BEGIN';
export const ADD_CONTENT_SUCCESS = 'ADD_CONTENT_SUCCESS';
export const ADD_CONTENT_FAILURE = 'ADD_CONTENT_FAILURE';

export const EDIT_CONTENT_BEGIN = 'EDIT_CONTENT_BEGIN';
export const EDIT_CONTENT_SUCCESS = 'EDIT_CONTENT_SUCCESS';
export const EDIT_CONTENT_FAILURE = 'EDIT_CONTENT_FAILURE';

export const ARCHIVE_CONTENT_BEGIN = 'ARCHIVE_CONTENT_BEGIN';
export const ARCHIVE_CONTENT_SUCCESS = 'ARCHIVE_CONTENT_SUCCESS';
export const ARCHIVE_CONTENT_FAILURE = 'ARCHIVE_CONTENT_FAILURE';

export const SAVE_CONTENT_DATA_BEGIN = 'SAVE_CONTENT_DATA_BEGIN';
export const SAVE_CONTENT_DATA_SUCCESS = 'SAVE_CONTENT_DATA_SUCCESS';
export const SAVE_CONTENT_DATA_FAILURE = 'SAVE_CONTENT_DATA_FAILURE';

export const RESET_REFRESH = 'RESET_REFRESH';
