import { call, put } from 'redux-saga/effects';
import API from './ContentApis';
import * as actions from './ContentActions';
import { dispatchSnackbarError } from '../../utils/Shared';
import { takeLatest } from 'redux-saga/effects';
import * as types from './ContentTypes';

export function* getContentsReqSaga() {
  try {
    const response = yield call(API.getContents);

    yield put(actions.getContentsSuccess(response.data.contents));
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.getContentsFailure(err.response.data));
  }
}

export function* getContentDetailsReqSaga({ payload }) {
  try {
    const response = yield call(API.getContentDetails, payload);

    yield put(actions.getContentDetailsSuccess(response.data.details));
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.getContentDetailsFailure(err.response.data));
  }
}

export function* addContentReqSaga({ payload }) {
  try {
    yield call(API.addContent, payload);
    yield put(actions.addContentSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.addContentFailure(err.response.data));
  }
}

export function* editContentReqSaga({ payload }) {
  try {
    yield call(API.editContent, { ...payload });
    yield put(actions.editContentSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.editContentFailure(err.response.data));
  }
}

export function* archiveContentReqSaga({ payload }) {
  try {
    yield call(API.archiveContent, payload);
    yield put(actions.archiveContentSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.archiveContentFailure(err.response.data));
  }
}

export function* saveContentDataReqSaga({ payload }) {
  try {
    yield call(API.saveContentData, payload);
    yield put(actions.saveContentDataSuccess());
  } catch (err) {
    // console.log(err.message);
    dispatchSnackbarError(err.message);
    yield put(actions.saveContentDataFailure(err.response.data));
  }
}

export function* ContentSagas() {
  yield takeLatest(types.GET_CONTENTS_BEGIN, getContentsReqSaga);
  yield takeLatest(types.GET_CONTENT_DETAILS_BEGIN, getContentDetailsReqSaga);
  yield takeLatest(types.ADD_CONTENT_BEGIN, addContentReqSaga);
  yield takeLatest(types.EDIT_CONTENT_BEGIN, editContentReqSaga);
  yield takeLatest(types.ARCHIVE_CONTENT_BEGIN, archiveContentReqSaga);

  yield takeLatest(types.SAVE_CONTENT_DATA_BEGIN, saveContentDataReqSaga);
}
