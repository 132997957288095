import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdBuild, MdHome, MdOutlinePages, MdViewInAr } from 'react-icons/md';

const Sidebar = () => {
  const { pathname } = useLocation();

  return (
    <div className="wrapper d-flex">
      <div className="sidebar">
        <ul>
          <li className={pathname.length === 1 ? 'active' : ''}>
            <Link to="/">
              <MdHome size="20" /> Home
            </Link>
          </li>
        </ul>

        <small className="text-muted px-3">Creator Tools</small>
        <ul>
          <li className={pathname.includes('fields') ? 'active' : ''}>
            <Link to="/fields">
              <MdBuild size="18" /> Field Creator
            </Link>
          </li>

          <li className={pathname.includes('models') ? 'active' : ''}>
            <Link to="/models">
              <MdViewInAr size="20" /> Model Creator
            </Link>
          </li>

          <li className={pathname.includes('contents') ? 'active' : ''}>
            <Link to="/contents">
              <MdOutlinePages size="20" /> Content Creator
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
