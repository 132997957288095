import * as types from './AuthTypes';

const INITIAL_STATE = {
  submitting: false,
  isAuthenticated: false,
  tokens: null,
  error: null,
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGIN_BEGIN:
      return {
        ...state,
        submitting: true,
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        submitting: false,
        error: null,
      };

    case types.LOGIN_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };

    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        tokens: null,
        error: null,
      };

    default:
      return state;
  }
};

export default AuthReducer;
