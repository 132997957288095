import { combineReducers } from 'redux';
import loader from '../Loader/LoaderReducer';
import snackbar from '../Snackbar/SnackbarReducer';
import auth from '../Auth/AuthReducer';
import fields from '../Fields/FieldReducer';
import models from '../Models/ModelReducer';
import contents from '../Contents/ContentReducer';

export default combineReducers({
  loader,
  snackbar,
  auth,
  fields,
  models,
  contents,
});
