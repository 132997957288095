import * as types from './FieldTypes';

const INITIAL_STATE = {
  submitting: false,
  refresh: false,
  fields: [],
  error: null,
};

const FieldReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_FIELD_SUCCESS:
      return {
        ...state,
        fields: action.payload,
        error: null,
      };

    case types.GET_FIELD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case types.ADD_FIELD_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case types.ADD_FIELD_SUCCESS:
      return {
        ...state,
        submitting: false,
        refresh: true,
        error: null,
      };

    case types.ADD_FIELD_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };

    case types.EDIT_FIELD_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case types.EDIT_FIELD_SUCCESS:
      return {
        ...state,
        submitting: false,
        refresh: true,
        error: null,
      };

    case types.EDIT_FIELD_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };

    case types.ARCHIVE_FIELD_SUCCESS:
      return {
        ...state,
        refresh: true,
        error: null,
      };

    case types.ARCHIVE_FIELD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case types.RESET_REFRESH:
      return {
        ...state,
        refresh: false,
      };

    default:
      return state;
  }
};

export default FieldReducer;
