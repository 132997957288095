import * as types from './ModelTypes';

const INITIAL_STATE = {
  submitting: false,
  refresh: false,
  models: [],
  details: null,
  error: null,
};

const ModelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_MODEL_SUCCESS:
      return {
        ...state,
        models: action.payload,
        error: null,
      };

    case types.GET_MODEL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case types.ADD_MODEL_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case types.ADD_MODEL_SUCCESS:
      return {
        ...state,
        submitting: false,
        refresh: true,
        error: null,
      };

    case types.ADD_MODEL_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };

    case types.EDIT_MODEL_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case types.EDIT_MODEL_SUCCESS:
      return {
        ...state,
        submitting: false,
        refresh: true,
        error: null,
      };

    case types.EDIT_MODEL_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };

    case types.ARCHIVE_MODEL_SUCCESS:
      return {
        ...state,
        refresh: true,
        error: null,
      };

    case types.ARCHIVE_MODEL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case types.GET_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload,
        error: null,
      };

    case types.GET_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case types.ADD_MODEL_FIELD_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case types.ADD_MODEL_FIELD_SUCCESS:
      return {
        ...state,
        submitting: false,
        refresh: true,
        error: null,
      };

    case types.ADD_MODEL_FIELD_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };

    case types.EDIT_MODEL_FIELD_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case types.EDIT_MODEL_FIELD_SUCCESS:
      return {
        ...state,
        submitting: false,
        refresh: true,
        error: null,
      };

    case types.EDIT_MODEL_FIELD_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };

    case types.ARCHIVE_MODEL_FIELD_SUCCESS:
      return {
        ...state,
        refresh: true,
        error: null,
      };

    case types.ARCHIVE_MODEL_FIELD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case types.RESET_REFRESH:
      return {
        ...state,
        refresh: false,
      };

    default:
      return state;
  }
};

export default ModelReducer;
