export const BASE_URL = 'https://v3.api.hiehq.com/v1';

export const FIELD_TYPES = Object.freeze({
  STRING: 1,
  NUMBER: 2,
  BOOLEAN: 3,
  ARRAY: 4,
  PROTECTED: 5,
  EMAIL: 6,
  PHONE: 7,
});
